import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'main [app-institution]',
  templateUrl: './institution.component.html'
})
export class InstitutionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
