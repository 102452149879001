<div class="dc-modal alert {{type}} {{size}}">
  <div class="dc-modal__header" *ngIf="showTitle">
    <h2 class="dc-modal__title">{{ title }}</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body">
    <p>{{ body }}</p>
  </div>
  <div class="dc-modal__footer">
    <button
      (click)="onClick(button)"
      class="primary">
      {{ button.text }}
    </button>
  </div>
</div>
