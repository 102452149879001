import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PatientComponent } from './pages/patient/patient.component';
import { InstitutionListComponent } from './pages/institution-list/institution-list.component';
import { InstitutionDetailsComponent } from './pages/institution-details/institution-details.component';
import { InstitutionComponent } from './pages/institution/institution.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DCMapModule } from '@dc/ui/components/map/map.module';
import { DCLoadingModule } from '@dc/ui/components/loading/loading.module';
import { DCAccordionModule } from '@dc/ui/components/accordion/accordion.module';
import { DCInputModule } from '@dc/ui/components/input/input.module';
import { DCSelectModule } from '@dc/ui/components/select/select.module';
import { FormsModule } from '@angular/forms';
import { TelemedicineComponent } from './pages/telemedicine/telemedicine.component';
import { DCCallModule } from "@dc/ui/components/call/call.module";
import { AuthQueryGuard } from "./guards/auth-query.guard";
import { TokenInterceptor } from "./services/auth.interceptor";
import { DCModalModule } from "@dc/ui/components/modal/modal.module";
import { DCAlertComponent } from "@dc/ui/components/modal/alert/alert.component";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { DCConfirmComponent } from "@dc/ui/components/modal/confirm/confirm.component";

@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    AppComponent,
    PatientComponent,
    InstitutionListComponent,
    InstitutionDetailsComponent,
    InstitutionComponent,
    PrivacyPolicyComponent,
    TelemedicineComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    DCMapModule,
    DCLoadingModule,
    DCAccordionModule,
    DCInputModule,
    DCSelectModule,
    FormsModule,
    DCCallModule,
    DCModalModule,
    NgbModalModule
  ],
  providers: [
    AuthQueryGuard,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  entryComponents: [
    DCAlertComponent,
    DCConfirmComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
