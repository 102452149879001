<div class="page-telemedicine">
  <div class="dc-section pat-header center dark">
    <div class="dc-row">
      <div class="dc-col">
        <span class="pat-back dc-icon remove-close white" (click)="close()"></span>
        <img class="logo" src="/assets/images/logo-white.svg" alt="logo">
      </div>
    </div>
  </div>
  <dc-call *ngIf="currentUser && roomId" [mobile]="true" [roomId]="roomId" [defaultFullScreen]="true" [userName]="currentUser.first_name + ' ' + currentUser.last_name" [userImage]="currentUser.photo_url"></dc-call>
</div>
