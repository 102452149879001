<header class="dc-header" *ngIf="!router.url.includes('telemedicine')">
  <div class="dc-container">
    <div class="dc-row middle padding-horizontal">
      <div class="dc-col xs-3 logo">
        <a [routerLink]="['/doktorijum-za-pacijente']">
          <img src="../assets/images/doktorijum-logo-color.svg" alt="Doktorijum logo">
        </a>
      </div>
      <div class="dc-col xs-9 menu end" [hidden]="router.url === '/privacy-policy'">
        <div class="topnav" [ngClass]="{'show': navigationResponsiveShow}">
          <a [routerLink]="['/doktorijum-za-pacijente']" [routerLinkActive]= "'active'" (click)="changePage()">Za pacijente</a>
          <a [routerLink]="['/spisak-institucija']" [routerLinkActive]= "'active'" (click)="changePage()">Spisak institucija</a>
          <a [routerLink]="['/doktorijum-za-ordinacije']" [routerLinkActive]= "'active'" (click)="changePage()">Za ordinacije</a>
          <a href="mailto:office@doktorijum.com" class="menu-item-contact">
            <img class="menu-ic-mail" src="assets/images/ic/ic-mail-selected.svg" alt="mail-ic">
          </a>
        </div>
        <div class="topnav-responsive" (click)="toggleNav()">
          <div class="action">
            <div id="nav-icon" [ngClass]="{'open': navigationResponsiveShow}">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<router-outlet></router-outlet>
<footer class="dc-footer" *ngIf="!router.url.includes('telemedicine')">
  <div class="footer-main">
    <div class="dc-container">
      <div class="dc-row middle">
        <div class="dc-col xs-6 left">
          <img src="assets/images/doktorijum-logo-white.svg" alt="">
        </div>
        <div class="dc-col xs-6 right">
          <div class="social">
            <a href="https://www.facebook.com/doktorijum/" target="_blank" rel="noopener"><img src="assets/images/ic/ic-social-facebook.svg" alt=""></a>
            <a href="https://www.instagram.com/doktorijum/" target="_blank" rel="noopener"><img src="assets/images/ic/ic-social-instagram.svg" alt=""></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dc-row copyright">
    <div class="dc-col sm-6 left">
      <span>COPYRIGHT</span>
      Doktorijum © 2020
    </div>
    <div class="dc-col sm-6 right">
      <span>
        <a [routerLink]="'/privacy-policy'" target="_blank">Uslovi korišćenja</a> | <a [routerLink]="'/privacy-policy'" target="_blank">Politika privatnosti</a>
      </span>
    </div>
  </div>
</footer>
