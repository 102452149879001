import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AppointmentService } from "../../services/appointment.service";
import { ModalService } from "@dc/ui/components/modal/modal.service";

@Component({
  selector: 'main [app-telemedicine]',
  templateUrl: './telemedicine.component.html'
})
export class TelemedicineComponent implements OnInit {

  appointmentId: number;
  roomId: any;
  currentUser: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: AppointmentService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.appointmentId = this.route.snapshot.params.appointmentId;
    this.getAppointment();
  }

  getAppointment() {
    const params = {
      include: 'patient,visit'
    }
    this.appointmentService.getAppointment(this.appointmentId, params)
      .subscribe(
        (appointment) => {
          this.roomId = appointment.data.visit.room_id;
          this.currentUser = appointment.data.patient;
        },
        (error) => {
          this.router.navigate(['']);
          if (error.status == 401) {
            this.modalService.alert({
              title: 'Obaveštenje',
              body: 'Nije vam dozvoljen pristup sobi.'
            });
          }
        }
      )
  }

  close() {
    this.modalService.confirm({
      title: 'Napuštanje poziva',
      body: 'Da li ste sigurni da želite da napustite poziv?',
      buttons: [
        {
          role: 'cancel',
          text: 'Ne'
        },
        {
          text: 'Da',
          handler: () => {
            this.router.navigate([''])
          }
        }
      ]
    });
  }

}
