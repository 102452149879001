<div class="page-institution-details" [dcLoading]="showLoading">
  <section class="institution-details" *ngIf="institution">
    <div class="dc-container">
      <div class="dc-row margin-top">
        <div class="dc-col sm-9">
          <div class="institution-details__institution-info padding-horizontal">
            <img src="{{institution.logo_url}}" alt="Logo">
            <div class="name-address">
              <h1>{{institution?.name}}</h1>
              <span>{{institution?.address}}</span>
            </div>
          </div>
          <span class="institution-details__description" [innerHTML]="institution?.description"></span>
          <div class="institution-details__specialities">
            <h3>SPECIJALIZACIJE</h3>
            <ul class="institution-details__speciality-list">
              <li class="dc-col sm-3" *ngFor="let specialty of institution?.specialties">{{specialty.name}}</li>
            </ul>
          </div>
        </div>
        <div class="dc-col md-3 institution-details__iframe-wrapper" *ngIf="institution">
          <div class="institution-details__iframe" [ngClass]="{'showBottomFrame': showBottomFrame}">
            <div class="close dc-icon remove-close text-right" *ngIf="showBottomFrame" (click)="toggleIframe()"></div>
            <iframe [src]="iframeSrc" frameborder="0" #iframe></iframe>
          </div>
        </div>
      </div>
<!--      <div class="dc-row">-->
<!--        <div class="dc-col sm-8">-->
<!--          <h3>GALAERIJA</h3>-->
<!--        </div>-->
<!--        <div class="dc-col sm-4">-->
<!--          <h3>OCENE</h3>-->
<!--        </div>-->
<!--      </div>-->
      <div class="dc-row">
        <div class="dc-col text-center">
          <h3>LOKACIJA</h3>
        </div>
      </div>
      <div class="dc-row institution-details__location" *ngIf="institution">
        <div class="dc-col sm-3 padding-horizontal">
          <p>{{institution.address}}</p>
          <span class="dc-icon bus" *ngIf="institution.transportation_bus">
            <span>{{institution.transportation_bus}}</span>
          </span>
          <span class="dc-icon subway" *ngIf="institution.transportation_tram">
            <span>{{institution.transportation_tram}}</span>
          </span>
          <span class="dc-icon trolley" *ngIf="institution.transportation_trolley">
            <span>
              {{institution.transportation_trolley}}
            </span>
          </span>
          <div>
            <span class="dc-icon parking" *ngIf="institution.parking_available"></span>
            <span class="dc-icon wheelchair-access" *ngIf="institution.wheelchair_accessible"></span>
          </div>
        </div>
        <div class="dc-col sm-9 institution-details__location-map">
          <dc-map
            [height]="'450px'"
            [interactiveMarker]="false"
            [markerCenter]="[institution.longitude, institution.latitude]"
            [center]="[institution.longitude, institution.latitude]">
          </dc-map>
        </div>
      </div>
      <div class="dc-row padding-horizontal">
        <div class="dc-col text-center" *ngIf="institution.doctors">
          <h3>TIM</h3>
          <div class="doctor-card__wrapper">
            <div class="doctor-card margin-vertical" *ngFor="let doctor of institution.doctors">
              <div class="doctor-image empty dc-icon profile" *ngIf="!doctor.photo_url"></div>
              <div class="doctor-image" *ngIf="doctor.photo_url"  [ngStyle]="{'background-image': 'url(' + doctor.photo_url + ')'}"></div>
              <div class="doctor-info">
                <span class="name">{{doctor.first_name}} {{doctor.last_name}}</span>
                <br>
                <span class="title" *ngIf="doctor.title">{{doctor.title}}<br></span>
                <span class="doctor-info__specialties"
                      *ngFor="let specialty of doctor.specialties; let i = index">
                {{specialty.name}}<span [hidden]="i === 0">, </span>
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dc-row" *ngIf="specialties?.length > 0">
        <div class="dc-col text-center">
          <h3>USLUGE</h3>
          <dc-accordions>
            <div class="dc-row">
              <div class="dc-col sm-12">
                <dc-accordions>
                  <dc-accordion *ngFor="let specialty of specialties" [title]="specialty.name">
                    <div class="dc-row">
                      <div class="dc-col sm-12">
                        <table class="dc-table">
                          <tbody>
                          <tr class="dc-clickable" *ngFor="let service of specialty.services">
                            <td style="text-align: left">
                              <label class="dc-table__label">Naziv usluge</label>
                              <div class="dc-table__value">
                                <span>{{ service.name }}</span>
                              </div>
                            </td>
                            <td style="width: 100px;text-align: left">
                              <label class="dc-table__label" translate>Cena</label>
                              <div class="dc-table__value">
                                <span>{{ service.price }}</span>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </dc-accordion>
                </dc-accordions>
              </div>
            </div>
          </dc-accordions>
        </div>
      </div>
    </div>
  </section>
  <div class="newsletter top-70">
    <div class="dc-container text-center">
      <h2>Pretplatite se na našu email listu</h2>
      <div class="dc-row center justify-show">
        <div class="dc-col md-4">
          <p>Saznajte prvi najnovije vesti i koje akcije su u toku u institucijama koje koriste Doktorijum sistem.</p>
        </div>
      </div>
      <div class="dc-row center justify-show">
        <div class="dc-col md-5">
          <div class="dc-input__wrapper newsletter-form">
            <label class="dc-input">
              <input type="text" placeholder="Vaša email adresa...">
              <span class="input-button">Pretplatite se</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="iframe-starter" [ngClass]="{'show': showIframeToggle}" (click)="toggleIframe()">
  <span class="dc-icon appointment"></span>
  <span class="text">Zakažite</span>
</div>
