import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';
import { DCAccordionComponent } from './accordion.component';

@Component({
  selector: 'dc-accordions',
  templateUrl: './accordions.component.html'
})
export class DCAccordionsComponent implements OnInit, AfterContentInit {
  @ContentChildren(DCAccordionComponent) accordions: QueryList<DCAccordionComponent>;
  @Input() disabled: boolean = false;

  @Output() finish = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (this.disabled !== false) { this.disabled = true; }
  }

  ngAfterContentInit() {
    this.accordions.map(
      (accordion: DCAccordionComponent) => {
        accordion.activate.subscribe(() => {
          this.accordions.toArray()
            .map(
              (accordionItem) => { accordionItem.active = accordionItem === accordion; }
            );
        });
        accordion.next.subscribe(() => {
          for (let i = 0; i < this.accordions.length; i++) {
            this.accordions.toArray()[i].active = i === this.accordions.toArray().indexOf(accordion) + 1;
          }
        });
      }
    );
  }

  show(accordionSelected, index) {
    this.accordions.toArray().map((accordion) => { accordion.active = false; });
    this.accordions.toArray()[index].active = true;
  }

}
