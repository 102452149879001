import { Component, OnInit } from '@angular/core';
import { InstitutionService } from '../../services/institution.service';

@Component({
  selector: 'main [app-patient]',
  templateUrl: './patient.component.html'
})
export class PatientComponent implements OnInit {

  institutions: any[] = [];

  institutionsToShow = [5,7,10,13,16,20,23,24,28,35]

  showLoading: boolean = false;

  constructor(
    private institutionService: InstitutionService
  ) { }

  ngOnInit() {
    this.getInstitutions();
  }

  getInstitutions() {
    this.showLoading = true;
    const params = {
      show_on_website: true,
      has_open_slots: false,
      pagination: false
    };
    this.institutionService.getInstitutions(params)
      .subscribe(
        (institutions) => {
          this.institutionsToShow.map((id) => {
            institutions.data.map((institution) => {
              if (id === institution.id) {
                this.institutions.push(institution);
                this.showLoading = false;
              }
            })
          })
        },
        () => {
          this.showLoading = false;
        },
      );
  }

}
