<div class="home">
  <section class="landing">
    <div class="dc-container">
      <div class="dc-row middle align-show">
        <div class="dc-col sm-6 left">
          <img src="../../assets/images/app-mockup-android.png" alt="android">
        </div>
        <div class="dc-col sm-6 text-right right padding-horizontal">
          <h1>Doktorijum</h1>
          <p class="text-right">
            Vaša aplikacija za zakazivanje termina kod doktora. Unesite Vaše simptome, odaberite neku od ponuđenih ustanova i termin koji Vam odgovara i zakažite.
          </p>
          <div class="download-app text-right">
            <h2>Preuzmite aplikaciju na</h2>
            <div class="download-img">
              <a href="https://apps.apple.com/hr/app/doktorijum/id1400043606" target="_blank" rel="noopener"><img src="../../assets/images/button-app-store.svg" alt=""></a>
              <a href="https://play.google.com/store/apps/details?id=com.doktorijum.patient" target="_blank" rel="noopener"><img src="../../assets/images/button-google-play.svg" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="doktorijum-description top-70 padding-horizontal">
    <div class="dc-container">
      <div class="dc-row middle">
        <div class="dc-col sm-6 left">
          <img src="../../assets/images/app-mockup-ios.jpg" alt="app-ios">
        </div>
        <div class="dc-col sm-6 right left-50">
          <h2>Šta je Doktorijum aplikacija?</h2>
          <div class="subtitle">
            Doktori kojima verujete
          </div>
          <p>
            Doktorijum aplikacija Vam omogućava da lako i brzo zakažete pregled kod doktora po Vašem izboru. Doktorijum je više od telefonskog imenika zdravstvenih ustanova. Lično smo stupili u kontakt sa svakom od zdravstvenih ustanova u našem registru kako bismo Vam omogućili izbor pouzdanih doktora iz različitih oblasti medicine. Naš algoritam kroz nekoliko odgovora može da predloži specijalizaciju kojoj vaši simptomi odgovaraju, usmeravajući Vas ka preporučenom doktoru i zdravstvenoj ustanovi.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="step-1 top-70 padding-horizontal">
    <div class="dc-container">
      <div class="dc-row middle">
        <div class="dc-col sm-6 left">
          <h2>Unesite simptome</h2>
          <div class="subtitle">
            Pronađite specijalizaciju ili uslugu ili dopustite da Vas naš algoritam usmeri.
          </div>
          <p>Kroz nekoliko koraka, naš algoritam Vam može dati predlog specijalizacije koja najviše odgovara Vašim simptomima. Odaberite Nelagodnost (celokupno stanje) ili Bol (lokalizovani simptomi) i odgovorite na nekoliko pitanja.</p>
          <p class="top-25">Ako želite da zakažete termin kod doktora određene specijalizacije, možete direktno pretražiti naš registar klikom na neku od Odabranih specijalizacija sa početne strane.</p>
        </div>
        <div class="dc-col sm-6 right">
          <div class="screens-illustration">
            <img src="../../assets/images/app-screen-01.jpg" alt="app1" class="step-1-1">
            <img src="../../assets/images/app-screen-02.jpg" alt="app2" class="step-1-2">
          </div>
          <div class="note">
            *Institucije prikazane u ovim ekranima su izmišljene i predstavljaju primere. Za tačan spisak institucija koje koriste Doktorijum sistem, pogledajte stranu Spisak institucija.
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="step-2 padding-horizontal">
    <div class="dc-container">
      <div class="dc-row middle">
        <div class="dc-col sm-6 left">
          <div class="screens-illustration">
            <img src="../../assets/images/app-screen-03.jpg" alt="app1" class="step-1-1">
            <img src="../../assets/images/app-screen-04.jpg" alt="app2" class="step-1-2">
          </div>
        </div>
        <div class="dc-col sm-6 right text-right">
          <h2>ODABERITE VREME I MESTO</h2>
          <div class="subtitle">
            Odaberite ustanovu i termin koji Vam odgovara za odabranu ili predloženu specijalizaciju.
          </div>
          <p>U zavisnosti od specijalizacije, u aplikaciji će Vam se izlistati ustanove u kojima možete zakazati termin prema odabranom datumu i lokaciji. Slobodni termini u ustanovi se listaju na strani o ustanovi, gde takođe možete saznati dodatne informacije: kratki opis, lokaciju, kako doći do ustanove kao i da li ustanova ima sopstveni parking i prilaz za invalidska kolica. Svaka ustanova ima svoju ocenu koju su postavili pacijenti nakon održanih termina.</p>
        </div>
      </div>
    </div>
  </section>
  <section class="step-3 padding-horizontal">
    <div class="dc-container">
      <div class="dc-row middle">
        <div class="dc-col sm-6 left">
          <h2>ZAKAŽITE PREGLED</h2>
          <div class="subtitle">
            Zakažite posetu jednim klikom. Bez poziva i čekanja. Pratite vaše zakazane posete u aplikaciji.
          </div>
          <p>Nakon odabira željene ustanove i termina, dovoljno je da kliknete na dugme "Zakaži". Automatski ćete biti upisani u zakazane termine ustanove. Vaš termin uvek mošete proveriti u aplikaciji na strani Termini. Termin takođe možete otkazati ako niste u mogućnosti da dođete u ustanovu.</p>
          <p class="top-25">U slučaju da ustanova nije u mogućnosti da održi vaš termin, bićete obavešteni putem notifikacije u aplikaciji, sa navedenim razlogom zbog koga je Vaš termin otkazan.</p>
        </div>
        <div class="dc-col sm-6 right">
          <div class="screens-illustration">
            <img src="../../assets/images/app-screen-05.jpg" alt="app1" class="step-1-1">
            <img src="../../assets/images/app-screen-06.jpg" alt="app2" class="step-1-2">
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="step-4 padding-horizontal">
    <div class="dc-container">
      <div class="dc-row middle">
        <div class="dc-col sm-12">
          <img src="../../assets/images/patients-with-app.jpg" alt="" class="app-download-image">
          <div class="download-app">
            <h3>Preuzmite aplikaciju na</h3>
            <div class="download-img">
              <a href="https://apps.apple.com/hr/app/doktorijum/id1400043606" target="_blank" rel="noopener"><img src="../../assets/images/button-app-store.svg" alt=""></a>
              <a href="https://play.google.com/store/apps/details?id=com.doktorijum.patient" target="_blank" rel="noopener"><img src="../../assets/images/button-google-play.svg" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="home-institutions top-70 padding-horizontal position-relative" [dcLoading]="showLoading">
    <div class="dc-container">
      <h2 class="text-center">Institucije koje koriste Doktorijum</h2>
      <div class="dc-row middle center home-institutions__logos">
        <div class="dc-col md-3 logo-institution dc-clickable" *ngFor="let institution of institutions" [routerLink]="['/institucija', institution.id]">
          <img src="{{institution?.alt_logo_url || institution.logo_url}}" alt="">
        </div>
      </div>
      <div class="dc-row text-center center justify-show">
          <a [routerLink]="['/spisak-institucija']" class="primary dc-button">
            <span>Pogledajte sve institucije</span>
          </a>
      </div>
    </div>
  </section>
  <div class="newsletter top-70">
    <div class="dc-container text-center padding-horizontal">
      <h2>Pretplatite se na našu email listu</h2>
      <div class="dc-row center justify-show">
        <div class="dc-col md-4">
          <p>Saznajte prvi najnovije vesti i koje akcije su u toku u institucijama koje koriste Doktorijum sistem.</p>
        </div>
      </div>
      <div class="dc-row center justify-show">
        <div class="dc-col md-5">
          <div class="dc-input__wrapper newsletter-form">
            <label class="dc-input">
              <input type="text" placeholder="Vaša email adresa...">
              <span class="input-button">Pretplatite se</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
