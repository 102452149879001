<div class="page-privacy-policy">
  <div class="dc-container">
    <h3>1. Opšte informacije</h3>
    <p>Pravila o zaštiti privatnosti (dalje u tekstu: Pravila) propisuju koje vaše lične podatke prikupljamo, na koji način i u koje svrhe ih obrađujemo, kao i vaša prava povezana s zaštitom ličnih podataka.
      <br>
      Doktorijum (dalje u tekstu: mi) obrađuje lične podatke korisnika svojih usluga i posetilaca internet stranice Doktorijuma u skladu s važećim zakonima i drugim propisima kojima je regulisana zaštita ličnih podataka.
      <br>
      Poštujemo vašu privatnost i obrađujemo lične podatke isključivo na temelju zakonom ustanovljenih pravnih osnova, odnosno u skladu sa vašom saglasnošću za obradu.
    </p>
    <h3>2. NAČIN I SVRHE OBRADE LIČNIH PODATAKA</h3>
    <p>
      <strong>Korišćenje aplikacije</strong> <br>
      Pristup aplikaciji zahteva registraciju i prihvatanje uslova korišćenja aplikacije, kao i davanje saglasnosti za obradu vaših ličnih podataka. Dakle, vaše lične podatke prikupljamo i dalje obrađujemo na osnovu date saglasnosti. Prilikom vaše registracije prikupljamo sledeće vaše lične podatke: ime i prezime, JMBG, adresa, kontakt telefon i kontakt i-mejl, datum rođenja, pol, simptomi, lične anamneze i objektivni nalazi, dijagnoze, terapije, istorija bolesti, zdravstvene usluge pružene u toku posete izabranom lekaru, planirane zdravstvene usluge, upućivanja na specijalističke preglede i na bolničko lečenje, izdati medicinski dokumenti, prepisani lekovi, izdata medicinsko-tehnička pomagala i ugradni medicinski materijal.

      Prilikom korišćenja aplikacije prikupljamo sledeće vaše lične podatke: ime i prezime, JMBG, adresa, kontakt telefon i kontakt i-mejl, datum rođenja, pol, simptomi, lične anamneze i objektivni nalazi, dijagnoze, terapije, istorija bolesti, zdravstvene usluge pružene u toku posete izabranom lekaru, planirane zdravstvene usluge, upućivanja na specijalističke preglede i na bolničko lečenje, izdati medicinski dokumenti, prepisani lekovi, izdata medicinsko-tehnička pomagala i ugradni medicinski materijal.

      Vaši lični podaci biće vidljivi i dostupni samo  administratorima kao i lekaru kod kog izaberete da odete i kojem dozvolite uvid u podatke. Nikom drugom vaši lični podaci neće biti vidljivi/dostupni.

      Lične podatke prikupljamo i obrađujemo kako bismo vas uputili odgovarajućem lekaru/zdravstvenoj ustanovi shodno vašim zahtevima i simptomima koje ste naveli, kao i u svrhe vođenja vašeg elektronskog medicinskog kartona sa ciljem da se u budućnosti olakaša i unapredi zdravstvena usluga na koju ste upućeni. Lične podatke sa kojima ste nas upoznali obrađivaćemo za vreme postojanja aplikacije, odnosno za vrijeme trajanja vaše registracije. Nakon toga vaše lične podatke ćemo čuvati samo uz vašu izričitu saglasnost.
    </p>
    <p>
      <strong>Reklamne poruke</strong> <br>
      Ukoliko želite obaveštenje o drugim našim proizvodima i uslugama možete prijaviti vašu e-mail adrese putem obrasca na sledećem linku, bez potrebe registracije. U svakom trenutku možete odustati od primanja reklamnih poruka na način da sami isključite tu funkcionalnost kroz postavke korisničkog računa ili da odaberete ponuđenu opciju povlačenja saglasnosti u primljenoj poruci ako se niste registrovali.
      <br>
      <strong>Ankete i upitnici</strong> <br>
      Kroz ankete i upitnike prikupljamo informacije o vašem zadovoljstvu s našom uslugom i druge korisne podatke kako bi ih prilagodili vašim potrebama. Rezultati provedenih anketa ili upitnika su isključivo sumarni i anonimni. Za učešće u anketama ili upitnicima prijavljujete se svojom e-mail adresom ili odabirom opcije kroz svoj korisnički račun.
    </p>
    <h3>3. PRIMAOCI LIČNIH PODATAKA</h3>
    <p>
      Doktorijum će vaše lične podatke prosleđivati adekvatnoj zdravstvenoj ustanovi/ordinaciji/laboratoriji/individualnom lekaru isključivo za potrebe vašeg lečenja i u obimu koji je neophodsan za vaše lečenje. Drugim primaocima vaši lični podaci biće prosleđeni isključivo na osnovu zakonske obveze, opravdanog zahteva ovlašećenog javnog tela (npr. sudova), ili na vaš lični zahtev.
    </p>
    <h3> 4. ZAŠTITA LIČNIH PODATAKA</h3>
    <p>
      Prikupljene lične podatke obrađujemo uz obaveznu primenu odgovarajućih pravnih, tehničkih i organizacijskih mera sigurnosti kako bismo sprečili svaku neovlašćenu obradu podataka. Pristup vašim ličnim podacima ograničen je samo na ona lica kojima su te informacije nužne radi prižanja odgovarajuće zdravstvene usluge. Kontinuirano radimo na edukaciji svih lica  uklkjučenih u proces prižanja zdravstvene usluge o važnosti poverljivosti podataka te čuvanju privatnosti i zaštiti Vaših podataka. Prikupljamo samo one podatke koji su nužni za predmetnu obradu i ne čuvamo ih duže nego što je to potrebno, odnosno određeno zakonskim propisima.
    </p>
    <h3>5. Vremenski period tokom kog se čuvaju vaši podaci</h3>
    <p>
      Podaci koje dostavite ili do koji se prikupe tokom korišćenja aplikacije za potrebe vođenja elektronksog medicinskog kartona (podaci o promenama u zdravstvenom stanju, novim dijagnozama, prepisanim lekovima i sl.) se čuvaju onoliko koliko je potrebno da bi se ispunila  svrha zbog koje ste nam dostavili podatke, odnosno zbog kojih su podaci prikupljeni ili da bi se ispoštovale zakonske odredbe. U slučaju povlačenja date saglosnosti vaši lični podaci biće obrisani.
    </p>
    <h3>6. Vaša prava</h3>
    <p>
      U svakom trenutku od nas možete zatražiti informaciju o obradi vaših ličnih podataka i zatražiti ostvarivanje sledećih prava:
      <br>

      - pristup  podacima koji se odnose na vas, <br>
      - ispravku ili dopunu vaših ličnih podataka kako bi uvek bili tačni, <br>
      - brisanje ličnih podataka, <br>
      - ograničenje obrade ličnih podataka, npr. kada prigovarate tačnosti ličnih podataka, a za vreme dok ne proverimo njihovu tačnost.
      <br>
      - prenos podataka (prenos vaših podataka koji su nastali upotrebom korisničkog računa na mediji po vašem zahtevu),
      <br>
      - povlačenje saglasnosti za obradu podataka. <br>
    </p>
    <h3>7.  KONTAKT ZA ZAŠTITU LIČNIH PODATAKA</h3>
    <p>Zahtevi, pritužbe ili upiti koji se odnose na obradu i zaštitu ličnih podatka  možete uputiti na adresu elektronske pošte: office@doktorijum.com.
      Sadržaj izjave o privatnosti aplikacije možemo promeniti u bilo koje vreme bez prethodne najave i saglasnosti korisnika. Svaka promena izjave o privatnosti aplikacije biće objavljena na ovoj stranici.
      <br>
      Ova Pravila poslednji put su izmenjena 2019. godine.</p>
  </div>
</div>
