import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PatientComponent } from './pages/patient/patient.component';
import { InstitutionListComponent } from './pages/institution-list/institution-list.component';
import { InstitutionDetailsComponent } from './pages/institution-details/institution-details.component';
import { InstitutionComponent } from './pages/institution/institution.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TelemedicineComponent } from "./pages/telemedicine/telemedicine.component";
import { AuthQueryGuard } from "./guards/auth-query.guard";


const routes: Routes = [
  { path: '', redirectTo: '/doktorijum-za-pacijente', pathMatch: 'full'},
  { path: 'doktorijum-za-pacijente', component: PatientComponent },
  { path: 'spisak-institucija', component: InstitutionListComponent },
  { path: 'institucija/:institutionId', component: InstitutionDetailsComponent },
  { path: 'doktorijum-za-ordinacije', component: InstitutionComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'telemedicine/:appointmentId', component: TelemedicineComponent , canActivate: [AuthQueryGuard] },
  { path: 'telemedicine', component: TelemedicineComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
