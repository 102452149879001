import { Component, OnInit } from '@angular/core';
import { InstitutionService } from '../../services/institution.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'main [app-institution-list]',
  templateUrl: './institution-list.component.html'
})
export class InstitutionListComponent implements OnInit {

  institutions: any;
  cities: any;
  specialties: any;

  filters: {
    name: string,
    city: any,
    specialty_id: any
  } = {
    name: null,
    city: null,
    specialty_id: null
  }

  showLoading: boolean = false;

  constructor(
    private institutionService: InstitutionService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    window.scroll({top: 0});
    this.route.queryParams
      .subscribe(
        (params: any) => {
          if (params.name) { this.filters.name = params.name; }
          if (params.city) { this.filters.city = params.city; }
          if (params.specialty_id) { this.filters.specialty_id = params.specialty_id; }
        }
      );
    this.getInstitutions();
    this.getLocations();
    this.getSpecialties();
  }

  getInstitutions() {
    this.showLoading = true;
    const params: any = {
      pagination: false,
      has_open_slots: false,
      show_on_website: true,
      include: 'images'
    };
    if (this.filters.name) { params.name = this.filters.name; }
    if (this.filters.city) { params.city = this.filters.city; }
    if (this.filters.specialty_id) { params.specialty_id = this.filters.specialty_id; }

    this.institutionService.getInstitutions(params)
      .subscribe(
        (institutions) => {
          this.institutions = institutions.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      )
  }

  getLocations() {
    this.showLoading = true;
    this.institutionService.getCities()
      .subscribe(
        (cities) => {
          const newCities = [];
          cities.data.map((city) => {
            newCities.push({
              id: city.name,
              name: city.name
            });
          });
          this.cities = newCities;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        },
      )
  }

  getSpecialties() {
    this.showLoading = true;
    this.institutionService.getSpecialities()
      .subscribe(
        (specialties) => {
          this.specialties = specialties.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      )
  }

  checkIfSearchEmpty(searchTerm: any) {
    if (searchTerm.length === 0) {
      this.filters.name = null;
      this.getInstitutions();
      this.setFilters();
    }
  }

  search(searchTerm: any) {
    if (searchTerm.value.length > 0) {
      this.getInstitutions();
      this.setFilters();
    }
  }

  setFilters() {
    this.router.navigate(['/spisak-institucija'], {queryParams: {...this.filters}})
  }

}
