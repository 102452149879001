<div class="page-for-ordination">
  <section class="landing">
    <div class="dc-container">
      <div class="dc-row middle align-show">
        <div class="dc-col sm-6 left padding-horizontal" style="padding-bottom: 16px">
          <img src="assets/images/institutions-mockup-mackbook.png" alt="institution mackbook">
        </div>
        <div class="dc-col sm-6 text-right right">
          <h1>Doktorijum za ordinacije</h1>
          <p class="text-right">
            Doktorijum je rešenje za zdravstvene ustanove i sisteme koji žele da upotrebom moderne tehnologije svom poslovanju pruže dodatnu priliku za uspeh.
          </p>
          <div class="more">
            <a href="mailto:office@doktorijum.com" target="_blank" class="dc-button white"><span>Zakažite prezentaciju portala</span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="doktorijum-description top-70">
    <div class="dc-container">
      <div class="dc-row middle">
        <div class="dc-col sm-6 left">
          <img src="assets/images/institutions-photo-mockup.jpg" alt="app-ios">
        </div>
        <div class="dc-col sm-6 right left-50">
          <h2>Šta je Doktorijum za zdravstvene ustanove?</h2>
          <div class="subtitle">
            Konkretno. Jednostavno. Direktno.
          </div>
          <p>Doktorijum sistem sačinjavaju dve celine: portal za zdravstvene ustanove i aplikacija za pacijente. Pacijenti pronalaze zdravstvene ustanove kroz unos simptoma koje osećaju ili prema odabranoj specijalizaciji. Zdravstvene ustanove postavljaju dostupne termine za svoje doktore i imaju pristup elektronskim kartonima pacijenata.</p>
          <p class="top-25">Ključne pogodnosti Doktorijuma su: jedan sistem za sve zaposlene, sigurnost podataka, uvid u celokupnu istoriju bolesti pacijenata, pronalaženje novih pacijenata a samim tim i povećanje zarade. </p>
        </div>
      </div>
    </div>
  </section>
  <section class="portal-info top-70">
    <div class="dc-container">
      <h2 class="text-center">Zašto doktori biraju Doktorijum portal? </h2>
      <div class="dc-row middle">
        <div class="dc-col sm-4 text-center">
          <img src="assets/images/ic/illustration-appointments.svg" alt="">
          <h3>Svi termini <br>na jednom mestu</h3>
          <div class="description left-50 right-50">Doktorijum štedi vreme Vama i Vašim pacijentima jednostavnom evidencijom slobodnih termina i lakim zakazivanjem.</div>
        </div>
        <div class="dc-col sm-4 text-center">
          <img src="assets/images/ic/illustrations-ehr.svg" alt="">
          <h3>Elektronski <br>kartoni pacijenata</h3>
          <div class="description left-50 right-50">U elektronskom kartonu, na jednom mestu se mogu pregledati istorija pacijenata, kao i pronaći prethodne posete ili rezultati.</div>
        </div>
        <div class="dc-col sm-4 text-center">
          <img src="assets/images/ic/illustration-institutions.svg" alt="">
          <h3>Pristup sa bilo <br>koje lokacije</h3>
          <div class="description left-50 right-50">Sve što vam je potrebno da pristupite Doktorijum portalu je pristup internetu i uređaj po vašem izboru - desktop računar, laptop, tablet, mobilni telefon.</div>
        </div>
      </div>
    </div>
  </section>
  <section class="doktorijum-description top-70">
    <div class="dc-container">
      <div class="dc-row middle">
        <div class="dc-col sm-6 left">
          <h2>Jednostavnost korišćenja i pristupačnost</h2>
          <div class="subtitle">
            Doktorijum aplikacija je jednostavna za korišćenje, od samog otvaranja termina do unosa detalja posete pacijenata.
          </div>
          <p>Celokupan Doktorijum sistem povezuje aplikaciju koju pacijenti imaju instaliranu na svom telefonu ili sličnom uređaju, sa portalom koji koristi zdravstvena ustanova. Na ovaj način osigurava se potpuna i apsolutna integracija sistema, dok se istovremeno poštuju najrigoroziniji sistemi enkripcije i stroga regulativa Evropske Unije o zaštiti podataka poznatija kao GDPR.</p>
          <p class="top-25">Ako želite da postanete deo Doktorijum mreže ili da zakažete sastanak sa našim predstavnikom, kontaktirajte nas.</p>
          <div class="dc-row text-center top-25 contact-btn">
            <a href="mailto:office@doktorijum.com" target="_blank" class="dc-button primary">
              <span>Kontaktirajte nas</span>
            </a>
          </div>
        </div>
        <div class="dc-col sm-6 right">
          <img src="assets/images/institutions-two-screens.png" alt="">
        </div>
      </div>
    </div>
  </section>
  <section class="paketi">
    <div class="dc-container">
      <h2 class="text-center">Paketi za ordinacije</h2>
      <div class="dc-row top-25">
        <div class="dc-col md-4">
          <div class="box">
            <div class="title">Starter</div>
            <div class="price">
              <span class="mark">€</span><span class="number">59</span><br>
              <div class="month">mesečno*</div>
            </div>
            <div class="description">
              <table>
                <tbody>
                <tr>
                  <td class="text-center " colspan="2">
                    Do 100 pregleda mesečno
                  </td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Zakazivanje pregleda</td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Pristup elekstronskom zdravstvenom kartonu</td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Čuvanje dokumentacije vezane za svakog pacijenta</td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Promocije na društvenim mrežama</td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Kreiranje foto i video materijala</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="btn-contact">
              <a href="mailto:office@doktorijum.com" target="_blank" >Kontaktirajte nas</a>
            </div>
          </div>
        </div>
        <div class="dc-col md-4">
          <div class="box">
            <div class="title">Standard</div>
            <div class="price">
              <span class="mark">€</span> <span class="number">99</span><br>
              <div class="month">mesečno*</div>
            </div>
            <div class="description">
              <table>
                <tbody>
                <tr>
                  <td class="text-center" colspan="2">
                    Od 101 do 250 pregleda mesečno
                  </td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Zakazivanje pregleda</td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Pristup elekstronskom zdravstvenom kartonu</td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Čuvanje dokumentacije vezane za svakog pacijenta</td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Promocije na društvenim mrežama</td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Kreiranje foto i video materijala</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="btn-contact">
              <a href="mailto:office@doktorijum.com" target="_blank" >Kontaktirajte nas</a>
            </div>
          </div>
        </div>
        <div class="dc-col md-4">
          <div class="box">
            <div class="title">PRO</div>
            <div class="price">
              <span class="mark">€</span> <span class="number">159</span><br>
              <div class="month">mesečno*</div>
            </div>
            <div class="description">
              <table>
                <tbody>
                <tr>
                  <td class="text-center" colspan="2">
                    Od 251 do 500 pregleda mesečno
                  </td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Zakazivanje pregleda</td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Pristup elekstronskom zdravstvenom kartonu</td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Čuvanje dokumentacije vezane za svakog pacijenta</td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Promocije na društvenim mrežama</td>
                </tr>
                <tr>
                  <td><img src="assets/images/ic/round-selected.svg" alt=""></td>
                  <td>Kreiranje foto i video materijala</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="btn-contact">
              <a href="mailto:office@doktorijum.com" target="_blank" >Kontaktirajte nas</a>
            </div>
          </div>
        </div>
      </div>
      <div class="dc-row">
        <div class="dc-col text-center top-25">
          <span class="small-info"><sup>*</sup>Plaćanje se vrši u dinarskoj protivvrednosti.</span>
          <span class="small-info"><sup>*</sup> Paketi za veće zdravstvene ustanove i sisteme se formiraju posedno.</span>
        </div>
      </div>
    </div>
  </section>
  <div class="newsletter top-70">
    <div class="dc-container text-center">
      <h2>Pretplatite se na našu email listu</h2>
      <div class="dc-row center justify-show">
        <div class="dc-col md-4">
          <p>Saznajte prvi najnovije vesti i koje akcije su u toku u institucijama koje koriste Doktorijum sistem.</p>
        </div>
      </div>
      <div class="dc-row center justify-show">
        <div class="dc-col md-5">
          <div class="dc-input__wrapper newsletter-form">
            <label class="dc-input">
              <input type="text" placeholder="Vaša email adresa...">
              <span class="input-button">Pretplatite se</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
