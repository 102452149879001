import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef, Host, HostListener,
  Input, OnChanges,
  OnInit, Optional,
  Output, SimpleChanges, SkipSelf,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'dc-select',
  templateUrl: './select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DCSelectComponent),
    multi: true
  }]
})

export class DCSelectComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {
  @ViewChild('searchInput', { read: ElementRef, static: false }) searchInput: ElementRef;
  @ViewChild('selectEl', { read: ElementRef, static: false }) selectEl: ElementRef;
  @ViewChild('listEl', { read: ElementRef, static: false }) listEl: ElementRef;

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  @Input() name: string;
  @Input() formControlName: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() searchPlaceholder: string = 'Search...';
  @Input() value: any;
  @Input() showSearch: boolean = false;
  @Input() multi: boolean = false;
  @Input() disabled: boolean = false;
  @Input() autoFocus: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() required: boolean = false;
  @Input() requiredType: 'default' | 'full' = 'default' ;
  @Input() showAddButton: boolean = false;
  @Input() addButtonText: string;
  @Input() addButtonShowValue: boolean = false;
  @Input() emptyStateText: string;
  @Input() notFoundText: string;
  @Input() actionInfoText: string;
  @Input() errorMessages: any = {};
  @Input() size: 'small' | 'default' = 'default';
  @Input() selectedOptions: any = null;
  @Input() options: any[] = [];
  @Input() showColor: boolean = false;
  @Input() showImage: boolean = false;
  @Input() showSelectAll: boolean = false;
  @Input() imageEmptyState: string;
  @Input() loading: boolean = false;
  @Input() autoPosition: boolean = true;
  @Input() hideColor: boolean = false;
  @Input() searchInputNotInFocus: boolean = false;
  @Input() showClearButton: boolean = true;

  @Output() addButtonClick = new EventEmitter();
  @Output() loadData = new EventEmitter();
  @Output() loadMore = new EventEmitter();
  @Output() searched = new EventEmitter();
  @Output() valueChange = new EventEmitter();

  config: PerfectScrollbarConfigInterface = {
    wheelPropagation: false
  };
  showList: boolean;
  selected: any[] = [];
  searchTerm: string;
  selectedAll: boolean = false;

  errors: any[] = [];
  showErrors: boolean = false;
  optionClicked: boolean = false;

  clearing: boolean = false;

  @HostListener('document:click', ['$event'])
  offClick(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showList = false;
    }
  }

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    if (this.required !== false) { this.required = true; }
    if (this.autoFocus !== false) { this.autoFocus = true; }
    if (this.disabled !== false) { this.disabled = true; }
    if (this.readOnly !== false) { this.readOnly = true; }
    if (this.showSearch !== false) { this.showSearch = true; }
    if (this.addButtonShowValue !== false) { this.addButtonShowValue = true; }
    if (this.multi !== false) { this.multi = true; }
    if (this.showColor !== false) { this.showColor = true; }
    if (this.showImage !== false) { this.showImage = true; }
    if (this.showSelectAll !== false) { this.showSelectAll = true; }
    if (this.showAddButton !== false) { this.showAddButton = true; }
    if (this.loading !== false) { this.loading = true; }
    if (this.autoPosition !== false) { this.autoPosition = true; }
    this.checkRequired();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.autoFocus) {
        if (this.selectEl) {
          this.selectEl.nativeElement.focus();
          this.selectEl.nativeElement.click();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && this.options) {
      this.options = this.options.map((option) => {
        const newOption: SelectItem = {
          id: option.id,
          name: option.name,
          photo_url: option.photo_url || option.logo_url,
          color: option.color,
          entity: option
        };
        if (option.first_name || option.last_name ) {
          newOption.name = (option.title ? option.title + ' ' : '') + option.first_name + ' ' + option.last_name;
        }
        return newOption;
      });
      if (this.options.some((item) => item.color) && !this.hideColor) { this.showColor = true; }
      if (this.options.some((item) => item.photo_url)) { this.showImage = true; }
      this.calculateSelectOptions();
    }
  }

  writeValue(value: any) {
    this.value = value;
    if (value) {
      this.calculateSelectOptions();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  toggle() {
    if (this.disabled) { return; }
    if (this.showList) {
      this.showList = false;
    } else {
      if (this.autoPosition) {
        const list = this.listEl.nativeElement.getBoundingClientRect();
        const space = window.innerHeight - list.bottom;

        // this.listEl.nativeElement.scrollHeight, some bug with this.listEl.nativeElement.offsetHeight...
        if (space < this.listEl.nativeElement.scrollHeight) {
          this.listEl.nativeElement.style.bottom = '42px';
        }else {
          this.listEl.nativeElement.style.bottom = 'auto';
        }
      }
      this.listEl.nativeElement.style.width = this.selectEl.nativeElement.offsetWidth + 'px';
      this.showList = true;
      if (this.searched) {
        this.searchTerm = '';
        this.searched.emit(this.searchTerm);
        setTimeout(() => {
          if (this.searchInput && !this.searchInputNotInFocus) {
            this.searchInput.nativeElement.focus();
          }
        }, 300);
      }
    }
  }

  showListToggle() {
    if (this.disabled) { return; }
    setTimeout(() => {
      if (this.clearing) { return; }
      if (!this.showList) {
        this.listEl.nativeElement.style.width = this.selectEl.nativeElement.offsetWidth + 'px';
        setTimeout(() => {
          this.showList = true;
          if (this.searched) {
            this.searchTerm = '';
            setTimeout(() => {
              if (this.searchInput && !this.searchInputNotInFocus) {
                this.searchInput.nativeElement.focus();
              }
            }, 300);
          }
        }, 200);
      } else {
        this.displayError();
      }
    }, 100)
  }

  hideList() {
    this.searchTerm = '';
    this.showList = false;
  }

  optionSelect(option) {
    if (this.multi) {
      if (!this.value) { this.value = []; }
      const index = this.value.indexOf(option.id);
      if (index !== -1) {
        this.value.splice(index, 1);
        this.selectedAll = false;
      } else {
        this.value.push(option.id);
      }
      this.calculateSelectOptions();
    } else {
      this.optionClicked = true;
      this.selected = [option];
      this.value = option.id;
      this.showList = false;
      this.searchTerm = '';
      setTimeout(() => {
        this.optionClicked = false;
      }, 600)
    }

    this.emitChange();
    this.displayError();
  }

  calculateSelectOptions() {
    if (this.value && this.options) {
      if (this.value.length) {
        this.selected = this.options.filter((option) => {
          return this.value.indexOf(option.id) !== -1;
        });
      } else {
        this.selected = this.options.filter((option) => {
          return this.value === option.id;
        });
      }
    }
  }

  clearOptions(event) {
    event.stopPropagation();
    this.selected = [];
    this.value = this.multi ? [] : null;
    if (!this.showList) { this.clearing = true; }
    setTimeout(() => { this.clearing = false; }, 200)
    this.calculateSelectOptions();
    this.emitChange();
    this.displayError();
  }

  emitSearch() {
    this.searched.emit(this.searchTerm);
  }

  emitLoadMore() {
    this.loadMore.emit();
  }

  emitChange() {
    if (this.onChange) {
      this.onChange(this.value);
    }
    this.valueChange.emit(this.value);
  }

  addClick(type?: string) {
    if (type === 'search') {
      if (this.options.length === 0 || !this.options) {
        this.addButtonClick.emit(this.searchTerm);
      }
    } else {
      if (this.searchTerm.length > 0) {
        this.addButtonClick.emit(this.searchTerm);
      } else {
        this.addButtonClick.emit( null );
      }
    }
    this.searchTerm = '';
  }

  checkRequired() {
    if (!this.formControlName) { return; }

    const formControl = this.controlContainer.control.get(this.formControlName);

    if (formControl.validator) {
      const validators = formControl.validator({}as AbstractControl);
      if (validators && validators.required) {
        this.required = true;
      }
    }
  }

  displayError() {
    if (!this.formControlName) { return; }

    const formControl = this.controlContainer.control.get(this.formControlName);

    if (formControl.errors) {
      const errors = [];

      for (const [key, value] of Object.entries(formControl.errors)) {
        errors.push(key);
      }

      this.errors = errors;
    } else {
      this.errors = [];
    }
  }

  toggleErrors() {
    this.showErrors = !this.showErrors;
  }

  selectAll() {
    if (!this.selectedAll) {
      this.value = [];
      this.options.map((option) => {
        this.value.push(option.id);
      });
    } else {
      this.value = [];
    }
    this.selectedAll = !this.selectedAll;
    this.calculateSelectOptions();
    this.emitChange();
    this.displayError();
  }

  close() {
    this.showList = false;
  }

}

export class SelectItem {id: number; name: string; color?: string; photo_url?: string; entity: any; }

