import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthQueryGuard implements CanActivate {
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const appointmentId = route.params.appointmentId;
    if (route.queryParams.auth) {
      localStorage.setItem('jwt', route.queryParams.auth);
      this.router.navigate(['telemedicine', appointmentId], {
        queryParams: { auth: null },
        queryParamsHandling: 'merge'
      });

      return false;
    }

    return true;
  }
}
