import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCAccordionsComponent } from './accordions.component';
import { DCAccordionComponent } from './accordion.component';



@NgModule({
  declarations: [
    DCAccordionsComponent,
    DCAccordionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DCAccordionsComponent,
    DCAccordionComponent
  ]
})
export class DCAccordionModule { }
