<div class="page-institution-list">
  <section class="institution-list">
    <div class="dc-container">
      <div class="dc-row text-center">
        <div class="dc-col">
          <h1>SPISAK INSTITUCIJA</h1>
        </div>
      </div>
      <div class="dc-row space-24">
        <div class="dc-col md-4">
          <dc-input type="search" placeholder="Pretražite..." [(ngModel)]="filters.name" (ngModelChange)="checkIfSearchEmpty($event)" (searchTerm)="search($event);"></dc-input>
        </div>
        <div class="dc-col md-3">
          <dc-select placeholder="Lokacija" [options]="cities" [(ngModel)]="filters.city" (ngModelChange)="getInstitutions(); setFilters();"></dc-select>
        </div>
        <div class="dc-col md-3">
          <dc-select placeholder="Specijalizacija" [options]="specialties" [hideColor]="true" [(ngModel)]="filters.specialty_id" (ngModelChange)="getInstitutions(); setFilters();"></dc-select>
        </div>
      </div>
      <div class="dc-row space-32 middle" [dcLoading]="showLoading" style="position: relative;">
        <div class="dc-col md-3" *ngFor="let institution of institutions">
          <div class="card" [routerLink]="['/institucija', institution.id]">
            <div class="card-header"
                 [style.backgroundImage]="institution.images[0] ? 'url('+ institution.images[0]?.url +')' : 'url('+ 'assets/images/placeholder-institution-featured.svg' +')'"
                 [class.empty]="!institution.images[0]?.url"
            ></div>
            <div class="card-main align-center-flex">
              <div class="dc-row align-center-flex">
                <div class="dc-col md-4" style="margin-top: 0">
                  <img src="{{institution.logo_url}}" alt="">
                </div>
                <div class="dc-col md-8" style="margin-top: 0">
                  <h3><a>{{institution.name}}</a></h3>
                  <div class="ordination-address">{{institution.address}}, {{institution.city}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="newsletter top-70">
    <div class="dc-container text-center">
      <h2>Pretplatite se na našu email listu</h2>
      <div class="dc-row center justify-show">
        <div class="dc-col md-4">
          <p>Saznajte prvi najnovije vesti i koje akcije su u toku u institucijama koje koriste Doktorijum sistem.</p>
        </div>
      </div>
      <div class="dc-row center justify-show">
        <div class="dc-col md-5">
          <div class="dc-input__wrapper newsletter-form">
            <label class="dc-input">
              <input type="text" placeholder="Vaša email adresa...">
              <span class="input-button">Pretplatite se</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
