import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCInputDirective } from './input.directive';
import { DCInputComponent } from './input.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    DCInputComponent,
    DCInputDirective
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    DCInputComponent,
    DCInputDirective
  ]
})
export class DCInputModule { }
