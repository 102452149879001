<div class="dc-modal confirm {{type}} {{size}}">
  <div class="dc-modal__header" *ngIf="showTitle">
    <h2 class="dc-modal__title">{{ title }}</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" [innerHTML]="body"></div>
  <div class="dc-modal__footer">
      <button
        *ngFor="let button of buttons; let i = index"
        (click)="onClick(button)"
        [ngClass]="{'primary': i === buttons.length - 1, 'cancel': button.role === 'cancel' }">
        {{ button.text }}
      </button>
  </div>
</div>
