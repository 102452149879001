<span class="dc-select__label" *ngIf="label" (click)="hideList()">
  {{ label }}
  <sup class="required" *ngIf="label && required">*</sup>
</span>
<div class="dc-select"
     dcOffClick
     (offClick)="hideList()"
     [ngClass]="{
     'dc-select__multi': multi,
     'dc-select__color': showColor,
     'dc-select__image': showImage,
     'large': (showColor && !multi) || (showImage && !multi)}">
  <label  class="dc-select__control-display"
          tabindex="0"
          (click)="toggle()"
          (focus)="showListToggle()"
          (keydown.tab)="hideList()"
          (keydown.shift.tab)="hideList()"
          [ngClass]="{
          'error': errors.length > 0,
          'disabled': disabled,
          'readOnly': readOnly,
          'open': showList,
          'required-full': requiredType === 'full',
          'small': size === 'small' }"
          [style.pointer-events]="readOnly ? 'none' : 'pointer'"
          #selectEl>
    <span class="dc-select__placeholder" *ngIf="selected.length === 0">{{ placeholder }}</span>
    <span class="dc-select__deselectAll" *ngIf="selected.length > 0 && showClearButton" (click)="clearOptions($event)"></span>
    <span class="dc-select__multiple-value" *ngIf="multi">
        <span class="selected-option"
              *ngFor="let option of selected; let i = index">
          <span class="option-color-multi" *ngIf="showColor" [style.backgroundColor]="option.color"></span>
          <span class="option-text">
            <span>{{option.name}}</span>
            <span [hidden]="selected.length === 1 || i === selected.length - 1">,&nbsp;</span>
          </span>
        </span>
    </span>
    <span class="dc-select__value" *ngIf="!multi && selected.length > 0">
      {{selected[0].name}}
    </span>
    <span class="option-color-single" *ngIf="showColor && !multi" [style.backgroundColor]="selected[0]?.color"></span>
    <span class="option-image-single"
          *ngIf="showImage && !multi"
          [class.fadeIn] = "optionClicked"
          [style.background]="selected[0]?.photo_url ? 'url(' + selected[0]?.photo_url + ')': (imageEmptyState ? 'url(' + imageEmptyState + ')' : '#c6c6c6')">
    </span>
  </label>
  <div
    class="dc-select__list-container"
    [ngClass]="{
    'dc-show': showList,
    'clearing': clearing
    }"
    (scrolled)="emitLoadMore()"
    #listEl>
    <div class="dc-close__list">
      <p class="label">{{ !label ? placeholder : label }}</p>
      <span class="close" *ngIf="!multi" (click)="close()"></span>
    </div>
    <div class="dc-loading__wrapper small select" *ngIf="loading">
      <div class="dc-loading__rotate"></div>
    </div>
    <label class="search" *ngIf="showSearch">
      <input type="text"
             [placeholder]="searchPlaceholder"
             [(ngModel)]="searchTerm"
             (ngModelChange)="emitSearch()"
             (keydown.tab)="hideList()"
             (keyup.enter)="addClick('search')"
             #searchInput>
    </label>
    <div class="empty" *ngIf="!searchTerm && (!options || options.length <= 0)"><p>{{emptyStateText}}</p></div>
    <div class="not-found" *ngIf="searchTerm && (!options || options.length <= 0)">
      <p class="padding-bottom">{{notFoundText}}</p>
    </div>
    <div class="empty" *ngIf="(!options || options.length <= 0) && actionInfoText">
      <span>({{actionInfoText}})</span>
    </div>
    <perfect-scrollbar class="dc-select__options-container" [class.withSearch]="showSearch" [config]="config">
      <li class="option"
          *ngIf="multi && showSelectAll"
          (click)="selectAll()"
          [ngClass]="{
            'checked': selectedAll
             }">
        <label class="option-content">
          <input type="checkbox" [checked]="selectedAll">
          <span class="option-text" (click)="selectAll()">Select all</span>
        </label>
      </li>
      <li class="option"
          *ngFor="let option of options; let i = index"
          (click)="optionSelect(option)"
          [ngClass]="{
            'checked': multi && value?.includes(option.id),
            'checked-dark': value === option.id,
            'event-disabled': optionClicked
             }">
        <label class="option-content" *ngIf="multi">
          <input type="checkbox" [checked]="value?.includes(option.id)">
          <span class="option-color" (click)="optionSelect(option)" *ngIf="showColor && option?.color" [style.backgroundColor]="option?.color"></span>
          <span class="option-text" (click)="optionSelect(option)">
              {{option.name}}
          </span>
        </label>
        <div class="option-content" *ngIf="!multi">
          <span class="option-color" *ngIf="showColor" [style.backgroundColor]="option?.color"></span>
          <span class="option-image"
                *ngIf="showImage"
                (click)="optionSelect(option)"
                [style.background]="option?.photo_url ? 'url(' + option.photo_url + ')': (imageEmptyState ? 'url(' + imageEmptyState + ')' : '#c6c6c6')">
            </span>
          <span class="option-text">
              {{option.name}}
            </span>
        </div>
      </li>
      <div class="add"
          *ngIf="showAddButton || addButtonText && (!options || options.length <= 0)">
        <button class="dc-button primary dc-icon plus"
                (click)="addClick()"
                [class.active]="!this.options || this.options.length <= 0">
          <span>{{addButtonText}}</span>
        </button>
      </div>
    </perfect-scrollbar>
    <div class="responsive-actions" *ngIf="multi">
      <div class="button-clear" [class.show]="selected.length > 0" (click)="clearOptions($event); showList = false">
        <button>Clear</button>
      </div>
      <div class="button-confirm" [class.show]="multi" (click)="showList = false">
        <button>Confirm</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="errors.length > 0" class="dc-datepicker__error-box">
  <div *ngIf="errors.length === 1" class="dc-datepicker__error single">
    <span>{{ errorMessages[errors[0]] || errors[0] }}</span>
  </div>
  <div *ngIf="errors.length > 1" class="dc-datepicker__error multi">
    <p (click)="toggleErrors()">
      <span>Imate {{ errors.length }} greške</span>
    </p>
    <div *ngIf="showErrors">
      <ul>
        <li *ngFor="let error of errors">
          {{ errorMessages[error] || error }}
        </li>
      </ul>
    </div>
  </div>
</div>
