import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dc-accordion',
  templateUrl: './accordion.component.html'
})
export class DCAccordionComponent implements OnInit {
  @Input() title: string;
  @Input() active: boolean;
  @Input() info: string;
  @Output() activate = new EventEmitter();
  @Output() next = new EventEmitter();
  show: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if (!this.active) { this.active = false; }
  }

  _activate() {
    this.activate.emit(this);
  }

}
