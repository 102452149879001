import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {

  constructor(
    private apiService: ApiService
  ) { }

  getInstitutions(params: any = {}) {
    return this.apiService.get(`institution`, params);
  }

  getInstitution(institutionId: any, params: any = {}) {
    return this.apiService.get(`institution/${institutionId}`, params);
  }

  getSpecialities(params: any = {}) {
    return this.apiService.get(`specialty`, params);
  }

  getInstitutionSpecialities(institutionId: any, params: any = {}) {
    return this.apiService.get(`institution/${institutionId}/specialty`, params);
  }

  getCities() {
    return this.apiService.get(`city`);
  }

}
