import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'website';
  navigationResponsiveShow: boolean = false;

  constructor(
    public router: Router
  ) {}

  toggleNav() {
    this.navigationResponsiveShow = !this.navigationResponsiveShow;
    if (this.navigationResponsiveShow) {
      document.body.classList.add('no-scroll');
      window.scrollTo(0, 0);
    } else {
      document.body.classList.remove('no-scroll')
    }
  }

  changePage() {
    this.navigationResponsiveShow = false;
    document.body.classList.remove('no-scroll')
  }
}
