import { Component, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'dc-call',
  templateUrl: './call.component.html'
})
export class DCCallComponent implements OnInit, OnDestroy {
  @HostBinding('class.full-screen') fullScreen: boolean = false;
  @HostBinding('class.small') smallScreen: boolean = true;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.callResponsive(event.target.innerWidth);
  }
  @Input() roomId: string;
  @Input() title: string;
  @Input() userImage: string;
  @Input() userName: string;
  @Input() moderator: boolean = false;
  @Input() mobile: boolean = false;
  @Input() defaultFullScreen: boolean = false;

  @Output() screenSharingStatusChanged = new EventEmitter();
  @Output() videoStatusChanged = new EventEmitter();
  @Output() audioStatusChanged = new EventEmitter();

  showLoading: boolean = false;
  minimize: boolean = false;
  jitsi: any;
  participants: [];
  video: boolean = true;
  audio: boolean = true;
  responsive: boolean = false;

  constructor() { }

  ngOnInit() {
    this.joinTheRoom();
    this.callResponsive(window.innerWidth);
    if (this.defaultFullScreen) { this.fullScreen = true }
  }

  ngOnDestroy() {
    this.hangup();
  }

  loadScript(url, callback) {
    const script = document.createElement('script');
    script.type = 'text/javascript';

    script.onload = () => {
      callback();
    };

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  initJitsi() {
    if(!this.roomId) return;
    this.showLoading = true;
    const domain = 'tele.doktorijum.com';

    const toolbar = [ ];

    const options = {
      roomName: this.setRoomId(),
      width: '100%',
      height: '100%',
      interfaceConfigOverwrite: {
        SHOW_JITSI_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        VIDEO_QUALITY_LABEL_DISABLED: true,
        TOOLBAR_BUTTONS: toolbar,
      },

      parentNode: document.querySelector('#call'),
      onload: () => {
        this.showLoading = false;
      }
    };
    this.jitsi = new JitsiMeetExternalAPI(domain, options);
    this.jitsi.executeCommand('subject', ' ');
    this.jitsi.executeCommand('avatarUrl', this.userImage);
    this.jitsi.executeCommand('displayName', this.userName);

    this.jitsi.addEventListener('videoMuteStatusChanged', (event) => {
      this.videoStatusChanged.emit(event);
    });

    this.jitsi.addEventListener('audioMuteStatusChanged', (event) => {
      this.audioStatusChanged.emit(event);
    });
  }

  public toggleVideo() {
    this.jitsi.executeCommand('toggleVideo');
    this.video = !this.video;
  }

  public toggleAudio() {
    this.jitsi.executeCommand('toggleAudio');
    this.audio = !this.audio;
  }

  public hangup() {
    this.jitsi.executeCommand('hangup');
  }

  public joinTheRoom() {
    setTimeout(() => {
      this.loadScript('https://tele.doktorijum.com/external_api.js', () => {
        this.initJitsi();
      });
    }, 1000)
  }

  setRoomId(){
    this.roomId = this.roomId.trim();
    return this.roomId.replace(/(\w)(\S*)(\s*)/g, // matches words and spaces
      function(g0,g1,g2){
        return g1.toUpperCase() + g2.toLowerCase();
      }
    );
  }

  fullScreenToggle() {
    const callWindow = document.getElementsByTagName('dc-call');
    this.fullScreen = !this.fullScreen;
    if (this.responsive) { this.minimize = true; this.smallScreen = true; }
    if (this.fullScreen) { this.minimize = false;  callWindow[0].classList.remove('minimize'); }
  }

  minimizeCall() {
    this.minimize = !this.minimize;
    const callWindow = document.getElementsByTagName('dc-call');
    if (this.minimize) {
      this.fullScreen = false;
      callWindow[0].classList.add('minimize');
    } else {
      callWindow[0].classList.remove('minimize');
    }
    if (this.responsive) { this.fullScreen = true; this.smallScreen = false; }
  }

  callResponsive(width) {
    const callWindow = document.getElementsByTagName('dc-call');
    if (width <= 510) {
      this.responsive = true;
      if (this.minimize) {
        this.fullScreen = false;
        this.smallScreen = true;
      } else {
        this.fullScreen = true;
        this.smallScreen = false;
      }
    } else {
      this.responsive = false;
      if (this.fullScreen) {
        this.smallScreen = false;
        this.minimize = false;
        callWindow[0].classList.remove('minimize');
      } else {
        if (this.smallScreen && !this.minimize) {
          this.fullScreen = false;
          this.minimize = false;
          callWindow[0].classList.remove('minimize');
        }
        if (this.smallScreen && this.minimize) {
          this.fullScreen = false;
          this.smallScreen = false;
          this.minimize = true;
          callWindow[0].classList.add('minimize');
        }
      }
    }
  }
}
