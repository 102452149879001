import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { InstitutionService } from '../../services/institution.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'main [app-institution-details]',
  templateUrl: './institution-details.component.html'
})
export class InstitutionDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('iframe') iframe;

  institutionId: number;
  institution: any;
  iframeSrc: any;
  showIframeToggle: boolean = false;
  showBottomFrame: boolean = false;
  smallScreen: boolean = false;
  showLoading: boolean;

  specialties: any = [];

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    if (!this.smallScreen) {
      if ($event.target.scrollingElement.scrollTop > 630) {
        this.showIframeToggle = true;
      } else {
        this.showIframeToggle = false;
        this.showBottomFrame = false;
      }
    }
  }

  constructor(
    private institutionService: InstitutionService,
    private route: ActivatedRoute,
    public domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    window.scroll({top: 0});
    if (window.innerWidth <= 995) {
      this.smallScreen = true;
      this.showIframeToggle = true;
      this.showBottomFrame = false;
    }
    this.institutionId = this.route.snapshot.params.institutionId;
    this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl('https://embedded.doktorijum.com/i/' + this.institutionId + '?hideFooter=true');
    this.getInstitution();
    this.getServices();
  }

  ngAfterViewInit() { }

  getInstitution() {
    this.showLoading = true;
    const params = {
      include: 'specialties,doctors.specialties'
    };
    this.institutionService.getInstitution(this.institutionId, params)
      .subscribe(
        (response) => {
          this.institution = response.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      );

  }

  toggleIframe() {
    this.showBottomFrame = !this.showBottomFrame;
  }

  getServices() {
    this.showLoading = true;
    const params = {
      include: 'services'
    }
    this.institutionService.getInstitutionSpecialities(this.institutionId, params)
      .subscribe(
        (specialities) => {
          specialities.data.map((specialty) => {
            if (specialty.services.length > 0) {
              this.specialties.push(specialty)
            }
          })
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      )
  }

}
